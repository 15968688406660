import { defineStore } from 'pinia'

export type Toast = {
  id: number
  message: string
}

export const useToastsStore = defineStore('toasts', () => {
  const toasts = ref<Toast[]>([])

  function add(message: string) {
    toasts.value.push({
      id: Date.now(),
      message,
    })

    console.log('toast added')
  }

  function dismiss(id: number) {
    toasts.value = toasts.value.filter(toast => toast.id !== id)
  }

  return {
    toasts,
    add,
    dismiss,
  }
})
