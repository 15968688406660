import validate from "/builds/collection-rh/front-end/node_modules/nuxt/dist/pages/runtime/validate.js";
import auth_45global from "/builds/collection-rh/front-end/middleware/auth.global.ts";
import manifest_45route_45rule from "/builds/collection-rh/front-end/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  auth_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/builds/collection-rh/front-end/middleware/auth.ts"),
  guest: () => import("/builds/collection-rh/front-end/middleware/guest.ts"),
  login: () => import("/builds/collection-rh/front-end/middleware/login.ts"),
  organization: () => import("/builds/collection-rh/front-end/middleware/organization.ts"),
  reset: () => import("/builds/collection-rh/front-end/middleware/reset.ts"),
  "sanctum:auth": () => import("/builds/collection-rh/front-end/node_modules/nuxt-auth-sanctum/dist/runtime/middleware/sanctum.auth.js"),
  "sanctum:guest": () => import("/builds/collection-rh/front-end/node_modules/nuxt-auth-sanctum/dist/runtime/middleware/sanctum.guest.js")
}